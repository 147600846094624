





























import { useOrderType } from './compositions/useOrderType'
import { computed, defineComponent } from '@vue/composition-api'
import Order from '@/views/Programs/Orders/vues/Order.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'

export default defineComponent({
  name: 'OrderFrame.vue',
  components: {
    Order,
    AlertCloak,
  },

  setup(props, ctx) {
    function cloakable() {
      const type = useOrderType(ctx.root.$route).orderType
      const cloakables = [OrderTypesEnum.startup, OrderTypesEnum.league, OrderTypesEnum.gameday]
      return cloakables.includes(type.value)
    }
    return {
      cloakable: computed(() => cloakable()),
      alertTypes: [AlertCloakTypes.SALES_TAX],
      orderStep: computed(() => ctx.root.$route.params.step),
      orderType: computed(() => useOrderType(ctx.root.$route).orderType),
      orderStepChanged: async (step: string) => {
        const lowerStep = step.toLocaleLowerCase()
        if (ctx.root.$route.params.step != lowerStep) {
          await ctx.root.$router.push({
            params: { ...ctx.root.$router.currentRoute.params, step: lowerStep },
          })
        }
      },
    }
  },
})
