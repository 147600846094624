import { computed } from '@vue/composition-api'
import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import { Route } from 'vue-router'

/**
 * Defines the order type as a property
 * @param route - uses the route to determine the order type, I suppose this could get refactored later
 */
export function useOrderType(route: Route) {
  return { orderType: computed(() => route.params.orderType as OrderTypesEnum) }
}
